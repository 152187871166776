<template>
  <v-col :sm="sm" :xs="xs" :md="md" :lg="lg" :cols="cols">
    <v-tooltip
      top
      max-width="250"
      nudge-top="-15"
      transition="fade-transition"
      open-delay="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-card tile v-bind="attrs" v-on="on" class="log-card">
          <v-card-text>
            <div class="text--primary force-single-line">
              Book: #{{ log.book.bookNumber }}, {{ log.book.name }}
            </div>
            <div v-if="log.member" class="text--primary force-single-line">
              Member: #{{ log.member.memberNumber }},
              {{ log.member.name }}
            </div>
            <div v-else class="text--primary force-single-line">
              Sub Library: {{ log.subLibrary.name }}
            </div>
            <div class="text--primary force-single-line">
              Distribution Type: {{ distributionType }}
            </div>
            <div class="text--primary force-single-line">
              {{ distributionLabels.out }} on
              <span v-if="log.outDateTime">{{
                log.outDateTime | formatDateTime
              }}</span>
              <span v-else>{{ log.outDate | formatDate }}</span>
            </div>
            <div
              :class="[
                log.inDate ? 'text--primary' : 'warning--text',
                'force-single-line'
              ]"
            >
              <span v-if="log.inDateTime">
                {{ distributionLabels.in }} on
                {{ log.inDateTime | formatDateTime }}
              </span>
              <span v-else-if="log.inDate">
                {{ distributionLabels.in }} on {{ log.inDate | formatDate }}
              </span>
              <span v-else>Not yet returned</span>
            </div>
          </v-card-text>
        </v-card>
      </template>
      #{{ log.book.bookNumber }}, {{ log.book.name }} distributed to
      <template v-if="log.member">
        #{{ log.member.memberNumber }}, {{ log.member.name }}
      </template>
      <template v-else>
        a sub library named {{ log.subLibrary.name }}
      </template>
    </v-tooltip>
  </v-col>
</template>

<script>
import helpers from "@/helpers";
import { computed } from "@vue/composition-api";

export default {
  props: ["lg", "md", "sm", "xs", "cols", "log"],
  setup(props) {
    const distributionType = computed(() =>
      helpers.string.pascalCase(props.log?.type || "General")
    );
    const distributionLabels = computed(() => {
      const isCheckin = ["reference", "e-reader"].includes(props.log?.type);
      return {
        out: isCheckin ? "Check-In" : "Distributed",
        in: isCheckin ? "Check-Out" : "Returned"
      };
    });
    return { distributionLabels, distributionType };
  }
};
</script>

<style lang="scss" scoped>
.log-card::after {
  background-image: url("~@/assets/icons/log-bk-icon.svg");
  bottom: 18px !important;
}
</style>
