<template>
  <div style="height: 100%" class="logs">
    <LogsFilters />
    <v-progress-linear v-if="isLargeLoading" indeterminate></v-progress-linear>
    <v-container v-if="noRecordsFound" class="text-center py-10">
      No logs found.
    </v-container>
    <div class="books-listing-parent pb-8" ref="scrollable" v-bar>
      <div>
        <v-container
          :class="[isEmpty ? 'd-none' : '', !loadedAll ? 'pull-up-margin' : '']"
        >
          <v-row>
            <LogCard
              v-for="log in data"
              :key="log._id"
              :log="log"
              sm="6"
              xs="12"
              md="4"
              lg="4"
              cols="12"
            />
          </v-row>
        </v-container>
        <SkeltonLoader
          v-if="!loadedAll"
          cardHeight="142"
          loaderType="table-cell,paragraph,text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useLogs } from "@/modules";
import LogsFilters from "@/components/LogsFilters";
import SkeltonLoader from "@/components/SkeltonLoader";
import LogCard from "@/components/LogCard.vue";

export default {
  setup: useLogs,
  components: {
    LogsFilters,
    SkeltonLoader,
    LogCard
  }
};
</script>
